@charset "utf-8";
/* CSS Document */


html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5, h6, p, blockquote, pre,a, abbr, acronym, address, big, cite, code,del, dfn, em, img, ins, kbd, q, s, samp,small, strike, strong, sub, sup, tt, var,b, u, i, center,dl, dt, dd, ol, ul, li,fieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary,time, mark, audio, video {margin: 0;	padding: 0;	border: 0;	font-size: 100%;	font: inherit;	vertical-align: baseline;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block;}
body {	line-height: 1;}
ol, ul {	list-style: none;}
blockquote, q {	quotes: none;}
blockquote:before, blockquote:after,q:before, q:after {	content: '';	content: none;}
table {	border-collapse: collapse;	border-spacing: 0;}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 13px;
	padding-top: 48px;
}

h1 {
	font-size: 36px;
}

strong {
	font-weight: bold;
}

body, html {
	min-height: 100%;
}

.nawigacja {
	border-bottom: 1px solid rgba(255, 255, 255, 0.16);
	color: #ffffff;
	padding-left: 20px;
	padding-right: 20px;
	background-color: #37474F;
	margin-bottom: 0;
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
	top: 0;
	height: 48px;
}

.nawigacja-tytul {
	margin-left: -20px;
	min-width: 260px;
	float: left;
	height: 48px;
}

.nawigacja-tytul a {

}

.czysto:after {
	clear: both;
	display: block;
	content: ' ';
}

.nawigacja-pozostale {
	border-color: rgba(0,0,0,.2);
	padding-left: 0;
	padding-right: 0;
	max-height: 48px;
}

.nawigacja-ikony {
	list-style: none;
	padding-left: 0;
	float: left;
}

.nawigacja-ikony li {
	float: left;
	display: block;
	position: relative;
}

.nawigacja-ikony li a {
	color: #ffffff;
	padding: 12px 15px;
	line-height: 20px;
	display: block;
	font-size: 13px;
}

.nawigacja-ikony li.rozwijana-uzytkownik a {
	padding: 7px 15px;
}



.nawigacja-ikony li:hover a {
	background-color: rgba(0,0,0,.1);
}

.nawigacja-ikony a i {
	color: #ffffff;
	font-size: 21px;
}

.nawigacja-ikony li.rozwijana-uzytkownik a i {
	color: #ffffff;
	font-size: 16px;
}

.rozwijana .menu {
	margin-top: 1px;
	min-width: 180px;
	padding: 7px 0;
	color: #333333;
	box-shadow: 0 1px 3px rgba(0,0,0,.1);
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	margin: 2px 0 0;
	list-style: none;
	font-size: 13px;
	background-color: #ffffff;
	border: 1px solid #ddd;
	border-radius: 3px;
	background-clip: padding-box;
}

.otwarte .menu {
	display: block;
}

p.znaczniki {
	float: left;
	margin:0;
	padding: 11px 15px;
}

#notifications-nav-button {
	position:relative;
}

#notifications-nav-button p {
	position: absolute;
    bottom: 5px;
    left: 50%;
    border-radius: 11px;
    background-color: rgb(255, 87, 34);
    color: #ffffff;
    padding: 0px 5px;
    font-size: 9px;
    line-height: 1.8;
    margin-left: -14px;
}

span.sukces {
	background-color: #4CAF50;
	color: #ffffff;
	border-color: #4CAF50;
}

p.znaczniki span {
	display: inline-block;
	padding: 5px 7px;
	border-radius: 2px;
	font-size: 10px;
	line-height: 15px;
	text-transform: uppercase;
}

.nawigacja-ikony-prawa {
	margin-right: -15px;
	float: right;
}

.rozwijana-uzytkownik a {
	text-decoration: none;
}

.rozwijana-uzytkownik img {
	max-height: 30px;
    margin-top: -2.5px;
    border-radius: 50%;
    max-width: none;
    vertical-align: middle;
    border: 0;
}

.rozwijana-uzytkownik span {
	display: inline-block;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 6.5px;
	padding-bottom: 6.5px;
}

.rozwijana-uzytkownik i {

}

.kontyner-strony {
	width: 100%;
	display: table;
	table-layout: fixed;
	position: relative;
}

.strona-tresc {
	display: table-row;
}

.menu-lewa {
	position: relative;
	display: table-cell;
	z-index: 99;
	vertical-align: top;
	width: 260px;
	background-color: #263238;
	color: #ffffff;
}

.submenu-lewa {
	background-color: #FFFFFF;
	border-right: 1px solid #DDDDDD;
}

body.menu-zwiniete .menu-lewa {
	position: relative;
	display: table-cell;
	z-index: 99;
	vertical-align: top;
	width: 56px;
	background-color: #263238;
	color: #ffffff;
}

body.menu-zwiniete .submenu-lewa {
	position: relative;
	display: table-cell;
	z-index: 99;
	vertical-align: top;
	width: 260px;
	color: #ffffff;
	background-color: #FFFFFF;
}

.pasek-uzytkownik {
	padding: 20px 10px;
}

.tresc-podstrony {
	display: table-cell;
	vertical-align: top;
	background-color: #F5F5F5;
	width: 100%;
}

.img-sm {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	vertical-align: middle;
}

.komorka {
	display: table-cell;
	vertical-align: top;
}

.uzytkownik-imie span {
	margin-bottom: 2px;
	display: block;
	font-weight: 500;
}

.uzytkownik-imie div {
	color: rgba(255,255,255,.8);
	font-size:11px;
}

.pasek-uzytkownik ul li, .pasek-uzytkownik ul {
	padding: 0;
	margin:0;
	list-style: none;
}

.pasek-uzytkownik ul li i {
	font-size: 24px;
	color: #ffffff;
	padding: 0 15px;
}

.menu-glowne ul, .menu-glowne li {
	padding: 0;
	margin: 0;
	list-style: none;
}

.menu-glowne ul li {
	position: relative;
}

div.menu-glowne ul li.menu-naglowek {
	color: rgba(255,255,255,.5);
	font-weight: 400;
	padding: 10px 20px;
	font-size: 11px;
	text-transform: uppercase;
}

body.menu-zwiniete div.menu-glowne ul li.menu-naglowek span {
	display: none;
}

div.menu-glowne ul li a span {
	font-size: 14px;
	vertical-align: middle;
}

body.menu-zwiniete div.menu-glowne ul li a span {
	font-size: 14px;
	vertical-align: middle;
	display: none;
}

body.menu-zwiniete div.menu-glowne ul li:hover a span {
	display: block;
    position: absolute;
    top: 0;
    right: -260px;
    background-color: #26A69A;
    border: 1px solid #26A69A;
    padding: 11px 20px;
    width: 260px;
    text-align: left;
    color: #fff;
    cursor: pointer;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    box-sizing: border-box;
    z-index: 9999;
}


.menu-glowne ul li a {
	padding: 12px 20px;
	font-weight: 500;
	color: rgba(255,255,255,.75);
	display: block;
	text-decoration:none;
}

.menu-glowne ul li a i {
	margin-right: 15px;
	font-size: 17px;
	vertical-align: middle;
}

div.menu-glowne ul li:hover {
	background-color: #222D33;
}

div.menu-glowne ul li.menu-naglowek:hover {
	background-color: #263238;
}

div.menu-glowne ul li:hover a {
	color: #ffffff;
}

.nawigacja-tytul a {
	display: block;
	height: 48px;
	line-height: 48px;
	color: #ffffff;
	text-decoration: none;
	padding-left: 25px;
	font-size: 24px;
	font-weight: 500;
}

div.menu-glowne li.active, div.menu-glowne li.active:hover  {
	background-color: #64b5f6;
}

div.menu-glowne li.active a, div.menu-glowne li.active:hover a {
	color: #263238;
}


.naglowek-podstrony {
	background-color: #ffffff;
}

#link-powrotu {
	display: inline-block;
	padding-right: 10px;
}

#link-powrotu a {
	border:1px solid #000000;
	border-radius: 50% 50%;
	display: block;
	text-align: center;
	text-decoration: none;
}

#link-powrotu a i {
	font-size: 24px;
	color: #000000;
	vertical-align: middle;
	display: block;
	padding: 2px 9px;
}

.naglowek-tytul h4 {
	display: inline-block;
	font-size: 19px;
	font-weight: normal;
	color: #333333;
	margin:0;
	padding: 0;
	min-height: 30px;
	vertical-align: top;
	line-height: 30px;
}

.naglowek-tytul h4 span {
	font-weight: 500;
}

.naglowek-tytul {
	padding: 32px 20px;
	border-bottom: 1px solid #DDDDDD;
	position: relative;
}

.naglowek-podstrony {
	margin-bottom:0;
}

#ikony-naglowek {
	position: absolute;
	top: 14%;
	right: 20px;
}

#ikony-naglowek a {
	display: inline-block;
	text-decoration: none;
	margin-right: 10px;
	background-color: #ffffff;
	border-radius: 5px;
	border: 1px solid #ffffff;
	padding: 10px 10px;
}

#ikony-naglowek a:hover {
	display: inline-block;
	text-decoration: none;
	margin-right: 10px;
	background-color: #FBFBFB;
	border-radius: 5px;
	border: 1px solid #DDDDDD;
	padding: 10px 10px;
}

#ikony-naglowek a i {
	color: #2196F3;
	display: block;
	font-size: 22px;
	text-align: center;
}

#ikony-naglowek a span {
	display: block;
	padding-top: 10px;
	text-align: center;
	font-size: 13px;
	color: #333946;
}

#okruszki {
	background-color: #fbfbfb;
	border-bottom: 1px solid #DDDDDD;
	padding-left: 20px;
}

#okruszki ul, #okruszki ul li {
	list-style: none;
	padding: 0;
	margin:0;
}

#okruszki ul li:before {
	content: '/\00a0';
	padding: 0 5px;
	color: #333333;
}

#okruszki ul li:first-child:before {
	content: ' ';
	padding: 0;
}

#okruszki ul li {
	display: inline-block;
}

#okruszki ul li a {
	color: #333333;
	text-decoration: none;
	font-size: 13px;
	padding: 14px 0;
	display: inline-block;
}

#okruszki ul li.aktywna {
	color: #999999;
}

.zawartosc-podstrony {
	padding: 0;
}

body.menu-zwiniete div.uzytkownik-imie, body.menu-zwiniete div.pasek-uzytkownik .komorka:last-child {
	display: none;
}

.kategoria-tytul {
	border-bottom: 1px solid #dddddd;
	position: relative;
	margin:0;
	color: #333333;
	padding: 14px 0 14px 20px;
}

.kategoria-tytul span {
	font-size: 11px;
	font-weight: 500;
	text-transform: uppercase;
}

.kategoria-zawartosc {
	padding: 20px;
}

.wyszukiwarka {
	position: relative;
}

.wyszukiwarka input {
	width: 100%;
	height: 36px;
	padding: 7px 12px 7px 36px;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	border-radius: 3px;
	color: #000000;
}

.wyszukiwarka i {
	width: 36px;
	height: 36px;
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;
	line-height: 36px;
	color: #999999;
}

.kategoria-checkoxy label {
	color: #333;
}

.kategoria-checkoxy li {
	position: relative;
	min-height:30px;
	vertical-align: middle;
}

.kategoria-checkoxy li label {
	vertical-align: middle;
	line-height: 30px;
}

.przelacznik {
	box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
    border-color: rgb(223, 223, 223);
    background-color: rgb(255, 255, 255);
    transition: border 0.4s, box-shadow 0.4s;
    border: 1px solid #ddd;
    border-radius: 100px;
    cursor: pointer;
    display: inline-block;
    width: 44px;
    height: 22px;
    vertical-align: middle;
}

.menu-kategoria .przelacznik {
	position: absolute;
	right: 0;
	top: 0;
}

.przelacznik small {
	left: 0;
	transition: background-color 0.4s, left 0.2s;
	border-radius: 100px;
	width: 22px;
	position: absolute;
	top: 0;
	box-shadow: 0 1px 3px rgba(0,0,0,.4);
	background-color: #fff;
	height: 22px;
}

.kategoria-checkoxy input {
	display: none;
}

.kategoria-checkoxy input:checked + label span.przelacznik {
	box-shadow: rgb(100, 189, 99) 0px 0px 0px 12px inset;
    border-color: rgb(100, 189, 99);
    background-color: rgb(100, 189, 99);
    transition: border 0.4s, box-shadow 0.4s, background-color 1.2s;
}

.kategoria-checkoxy input:checked + label span.przelacznik small {
	left: 22px;
    transition: background-color 0.4s, left 0.2s;
    background-color: rgb(255, 255, 255);
}




/* LINIA CZASU */
.linia-czasu {
	padding: 10px 0 10px 0;
	position: relative;
}

.czas-wiersz {
	padding-left: 68px;
	position: relative;
	padding-bottom: 9px;
}

.czas-ikona {
	width: 63px;
	height: 48px;
	border-radius: 50%;
	margin: 0 auto;
	border: none;
	left: 0;
	position: absolute;
	top: 10px;
	z-index: 10;
	padding-left: 5px;
}

.czas-ikona img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	vertical-align: middle;
}

.czas-ikona:after {
	content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    height: 2px;
    width: 16px;
    background-color: #ccc;
    z-index: 1;
    right: 0;
    margin-left: 1px;
}

.panel-historia {
	color: #333333;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	box-shadow: 0 1px 1px rgba(0,0,0,.05);
	padding: 15px;
	border-radius: 5px;
}

.panel-historia h6 {
	padding: 10px 0;
	margin: 0;
	font-size: 15px;
	position: relative;
	font-weight: 500;
}

.panel-historia .dane {
	position: absolute;
	top: 17px;
	right: 20px;
}

.panel-historia .dane i {
	font-size: 16px;
}

.linia-czasu:before {
	position: absolute;
    top: 0;
    margin-left: -2px;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    z-index: 1;
    content: "";
    left: 24px;
    background-color: #ccc;
}

.linia-czasu-kontyner:after {
	position: absolute;
	top: 0;
	margin-left: -1px;
	height: 100%;
	width: 2px;
	background-color: #cccccc;
	content: ' ';
	left: 24px;

}

.dodanie-formularza h6 i {
	color: #1D9D74;
}

.panel-historia h6 i {
	font-size: 16px;
}

.czas-wiersz .tresc table {
	margin: 10px 0;

}

.czas-wiersz .tresc table tr td {
	border: 1px solid #DDDDDD;
	border-collapse: collapse;
	padding: 5px;
}

.czas-wiersz .tresc table thead tr td {
	background-color: rgba(206,206,206,0.1);

}

.czas-wiersz .tresc table tbody tr td:first-child {
	font-weight: bold;
}

.tabela-zmian p {
	font-weight: bold;
	padding-top: 15px;
}


/* K.LINIA CZASU */

body.logowanie {
	background-color: #1E3C50;
	background-image: url('../images/atlas_logo.png');
	background-position: center 7%;
	background-repeat: no-repeat;
}

#okno-logowania {
	overflow: hidden;
	border-radius: 5px;
	border: none;
}

body.logowanie div.panel-default {
	background-color: #EAEAEA;
}

body.logowanie h1 {
	text-align: center;
	font-weight: 100;
	padding-top: 15px;
	padding-bottom: 15px;
}

.rameczki div {
    height: 7px;
}

.rameczki:after {
	display: block;
	content: ' ';
	clear: both;
}

.rameczki div:nth-child(1) {
    background-color: #00829C;
}

.rameczki div:nth-child(2) {
    background-color: #7AB8BF;
}

.rameczki div:nth-child(3) {
    background-color: #EDC278;
}

.rameczki div:nth-child(4) {
    background-color: #CC6E91;
}

body.logowanie div#app {
	min-height: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
}

table.dataTable {
width: 100% !important;
margin:0;
}

table.dataTable thead th, table.dataTable thead td {
	border: none;
}

.table-bordered, .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
	border: none;
}

table.dataTable.no-footer {
	border: none;
}

#forms-table_length {
	display: none;
}

#forms-table_filter {
	display: none;
}

.table>thead>tr>th {
	border-bottom: 1px solid #bbb;
}

.dataTable th:hover {
    background-color: rgba(255, 255, 255, 0.73);
}

.dataTable tr:hover td {
	background-color: #f2eec4;
	cursor: pointer;
}

table.dataTable tbody tr td {
	border-top: 1px solid #ddd;
	vertical-align: middle;
}

.seba-submenu {
	display: inline-block;
	position: relative;
}

.seba-submenu div.submenu {
	position: absolute;
	top: 100%;
	right: 0;
	box-shadow: 0 1px 3px rgba(0,0,0,.1);
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	min-width:190px;
	z-index: 1000;
}

.seba-submenu div.submenu a {
	display: block;
	text-decoration: none;
	padding: 8px 15px;
	text-align: left;
}

.seba-submenu div.submenu a:hover {
	background-color: #2196F3;
	color: #ffffff;
}

.seba-submenu div.submenu a:hover i {
	color: #ffffff;
}

.seba-submenu div.submenu a i {
	margin-right: 12px;
	font-size: 16px;
	color: #333333;
}

.btn-datatable {
	display: inline-block;
    margin: 1px;
    padding: 9px 12px;
    background-color: #ffffff;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    text-decoration: none;
    color: #666666;
    font-size: 14px;
}

.inbox-tabela .btn-datatable {
	display: block;
    margin: 1px;
    padding: 9px 12px;
    background-color: #ffffff;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    text-decoration: none;
    color: #666666;
    font-size: 14px;
}


.btn-datatable:hover, .seba-submenu a.active {
	text-decoration: none;
	background-color: #2196F3;
	color: #ffffff;
}

.comment_repair{
    text-align: center;
    font-size: 14px;
    display: inline-block;
    margin: 1px;
    padding: 9px 16px;
    background-color: #ffffff;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    text-decoration: none;
    color: #666666;
}

a.aktywna_notatka{
    background: linear-gradient(#fed266, #FFB606);
    border: 1px solid #dea317;
}

.notifications-list{
    position: absolute;
    top:49px;
    border-radius: 4px;
    background-color: #37474F;
    right: 50px;
    color: #fff;
    padding: 15px;
    width: 370px;
    display: none;
    box-sizing: border-box;
}

.notifications-list > ul{
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.notifications-list .notification{
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    min-height: 40px;
    color: rgba(255, 255, 255, 0.74);
    position: relative;
    font-size: 11px;
}

.notifications-list .unread {
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    min-height: 40px;
    color: #37474F;
    position: relative;
    font-size: 11px;
}

.notification {
	background-color: #37474F;
    border: 1px solid rgba(255, 255, 255, 0.39);
}

.notifications-list div.unread {
	background-color: #FFFFFF;
    border: #FFFFFF;
}

.notification:hover {
	background-color: rgba(255, 255, 255, 0.09);
}

.notifications-list div.unread:hover {
	background-color: #f5f5cb;
}

.notifications-list div.notification:nth-child(even) {
	background-color: #FBFBFB;
}

.notifications-list .notification.unread{
    font-weight: 700;
}

.notifications-list .notification div.notification-controls{
	position: absolute;
	right: 0;
	top: 0;
	width: 65px;
	height: 100%;
}

.notifications-list .notification div.notification-controls:after {
	content: ' ';
	display: block;
	clear: both;
}

.notification-controls ul {
	padding-right:10px;
}

.notifications-list .notification div.notification-controls ul li {
	float: right;
}

.notifications-list .unread div.notification-controls ul li {
	float: left;
}

.notifications-list a{
    color: rgba(255, 255, 255, 0.77);
    font-size: 13px;
    padding-bottom: 3px;
    display: inline-block;
}

.notifications-list div.unread a{
    color: #37474F;
    font-size: 13px;
    padding-bottom: 3px;
    display: inline-block;
}

#mark-all-notifications-read {
	margin-right: 10px;
	display: inline-block;
	color:#a0bef3;
	margin-left: 5px;
	margin-top: 7px;
}

#delete-all-notifications {
	display: inline-block;
	color:#a0bef3;
}


.select-checkbox {
    position: relative;
}

.select-checkbox input[type="checkbox"] {
    background: transparent;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    margin-left: -9px;
    border: 2px solid #607D8B;
    border-radius: 2px;
    display: inline-block;
    color: #455A65;
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    box-shadow: none;
}

.select-checkbox input[type="checkbox"]:checked, .select-checkbox input[type="checkbox"]:checked:active {
	box-shadow: none;
}



.select-checkbox input[type="checkbox"]:checked:after {
	content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    line-height: 1;
    margin-top: -8px;
    margin-left: -8px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #4CAF50;
    transition: 300ms all;
    box-shadow: none;
}

table.dataTable tbody tr.selected, .dataTable tr.selected:hover td {
	    background-color: rgba(76, 175, 80, 0.17);

}

a.open-multiform {
	display: block;
}


table.dataTable tbody tr td:nth-child(2) {
	vertical-align: middle;
}





/* FORMULARZ */

.zawartosc-podstrony .panel {
	margin-bottom: 20px;
    border: 1px solid #ddd;
    color: #333;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.zawartosc-podstrony .panel-no-bg {
	margin-bottom: 20px;
    border: none;
    color: #333;
    background-color: transparent;
}

.zawartosc-podstrony .komentarz-ksiegowosc {
	margin:20px;
}

#form-naglowek {
	margin: 20px 20px 10px 20px;
	background-image: url('../images/form-naglowek.gif');
	background-position: top left;
	background-repeat: no-repeat;
	padding-left: 600px;
}

#form-naglowek table {
	width: 100%;
}

#form-naglowek table, #form-naglowek table td {
	border: none;
	padding: 0;
	margin: 0;
	vertical-align: top;
}

td.informacje-top div {
	border: 1px dotted #929292;
	background-color: rgba(0, 0, 0, 0.04);
	color: #494949;
	border-radius: 4px;
	padding: 5px;
	min-height: 60px;
	position: relative;
}

td.typ-zgloszenia div {
	margin: 0 10px;
}

td.typ-zgloszenia p {
	font-weight: bold;
	font-size: 17px;
	padding-top: 3px;
}

td.typ-zgloszenia span {
	font-size: 14px;
}

td.identyfikator-zgloszenia p {
	font-weight: bold;
	font-size: 17px;
	padding-top: 3px;
}

#form-naglowek td.kto-placi span.select2-container {
	display: block;
	width: 170px !important;
	position: absolute;
	top: 50%;
	margin-top: -14px;
}

td.kto-placi {
	width: 182px;
}

td.identyfikator-zgloszenia span {
	font-size: 14px;
}

body.type_stairlifts #form-info-urzadzenie td {
	background-color: rgba(255, 51, 0, 0.29);
}


body.type_lifts #form-info-urzadzenie td {
	    background-color: rgba(115, 0, 230, 0.25);
}


body.type_hoists #form-info-urzadzenie td {
	    background-color: rgba(230, 230, 0, 0.23);
}

body.type_beds #form-info-urzadzenie td {
	    background-color: rgba(0, 204, 0, 0.29);
}

#form-podstawowe {
	margin: 10px 20px;
}

#form-podstawowe table {
	width: 100%;
}

#form-podstawowe table, #form-podstawowe table td {
	margin:0;
	padding: 0;
	border:none;
}

#form-podstawowe table td div {
	border: 2px solid #929292;
	padding: 10px 5px;
	margin:5px;
}

#form-podstawowe table td div div{
	border: none;
	padding: 0;
	margin:0;
}

#form-podstawowe table td:first-child div {
	margin-left: 0;
}

#form-podstawowe table td:last-child div {
	margin-right: 0;
}

#form-podstawowe table td div span {
	display: block;
	text-align: center;
}

#form-podstawowe table td div p {
	font-size: 13px;
	text-align: center;
	font-weight: bold;
	padding-top: 3px;
}

#form-podstawowe table td div div p {
	font-size: 13px;
	text-align: center;
	font-weight: bold;
	padding: 3px 0 0 0;
}

#form-info-urzadzenie {
	margin: 10px 20px;
}

#form-info-urzadzenie table {
	width: 100%;
}

#form-info-urzadzenie table, #form-info-urzadzenie table td {
	margin:0;
	padding: 0;
	border:none;
}

#form-info-urzadzenie table td div {
	padding: 10px 5px 10px 15px;
}

#form-info-urzadzenie table td div div {
	padding: 0;
}

#form-info-urzadzenie table td:first-child {
	width: 380px;
}

#form-info-urzadzenie table td div p {
	font-weight: bold;
	padding-top:3px;
}

#form-info-urzadzenie table td div div p {
	font-weight: bold;
	padding-top:3px;
}

body.type_stairlifts #form-info-urzadzenie table td:first-child, body.type_lifts #form-info-urzadzenie table td:first-child, body.type_hoists #form-info-urzadzenie table td:first-child, body.type_beds #form-info-urzadzenie table td:first-child {
	background-color: #3D3D3D;
	color: #ffffff;
}


#form-legenda-tabela {
	margin: 10px 20px;
}

#form-legenda-tabela table {
	width: 100%;
}

#form-legenda-tabela table, #form-legenda-tabela table td {
	margin:0;
	padding: 0;
	border:none;
	text-align: center;
	table-layout: fixed;
}

#form-legenda-tabela table td div {
	padding: 5px;
}

#form-legenda-tabela table td div span {
	display: inline-block;
	padding:5px 5px;
	background-color: #3D3D3D;
	border-radius: 4px;
	font-size: 15px;
	text-transform: uppercase;
	color: #ffffff;
}

#form-legenda-tabela table td:first-child div span {
	background-color: #4CAF50;
}

#form-legenda-tabela table td div p {
	font-size: 15px;

}

#form-tresc-dokumentu {
	margin: 10px 20px;
}

#form-tresc-dokumentu table {
	width: 100%;
	table-layout: fixed;
}

#form-tresc-dokumentu table tr table {
	width: 100%;
	table-layout: auto;
	font-size: 13px;
	color: #434141;
	border-collapse: collapse;
	margin-bottom:10px;
}

#form-tresc-dokumentu table tr table tr td {
	border:1px solid #B6B6B6;
}

#form-tresc-dokumentu table tr table thead tr td {
	font-weight: bold;
	background-color: #37474F;
	color: #ffffff;
	padding: 5px;
	width: 50px;
	text-align: center;
}

#form-tresc-dokumentu table tr table thead tr td:first-child {
	width: auto;
	text-align: left;
}


#form-tresc-dokumentu table tr table tbody tr td {
	padding: 3px 5px;
	width: 50px;
	text-align: center;
}

#form-tresc-dokumentu table tr table tbody tr td:first-child {
	width: auto;
	text-align: left;
}

#form-tresc-dokumentu table tr table tbody tr td:nth-child(2) {
	color: #4CAF50;
}



#form-tresc-dokumentu table div.kolumna-informacyjna {
	margin-left: 10px;
}

#form-tresc-dokumentu table, #form-tresc-dokumentu table td {
	margin:0;
	padding: 0;
	border:none;
	vertical-align: top;
}

#form-tresc-dokumentu table td div {

}

#form-tresc-dokumentu .panel {
	border: none;
	margin-bottom: 10px;
}

#form-tresc-dokumentu .panel-heading {
	background-color: #37474F;
	border-radius: 4px 4px 0 0;
	border: 1px solid #37474F;
	color: #ffffff;
	padding: 10px 10px;
}

.zawartosc-podstrony .panel-heading {
	background-color: #37474F;
	border-radius: 4px 4px 0 0;
	border: 1px solid #37474F;
	color: #ffffff;
	padding: 10px 10px;
}

.zawartosc-podstrony .panel-body {
	border-radius: 0 0 4px 4px;
	border:1px solid #BCBBBB;
	padding: 20px;
	background-color: #FFFFFF;
	font-size: 14px;
	line-height: 1.3;
}

#form-tresc-dokumentu .panel-body {
	border-radius: 0 0 4px 4px;
	border:1px solid #BCBBBB;
	padding: 20px;
}

.form-navi {
	padding: 0;
}

.form-navi a {
	display: block;
	padding: 25px 0;
	text-align: center;
}

.form-navi a:hover {
	background-color: rgba(255, 252, 0, 0.32);
}

.form-navi a:hover p {
	transform: scale(1.1,1.1);
	transition: 200ms all;
}

.menu-kategoria div.active a:hover p, .menu-kategoria div.no-active a:hover p {
	transform: none;
	transition: all 0s ease 0s;
}

.menu-kategoria div.active a {
	background-color: #4CAF50;
}

.menu-kategoria div.no-active a {
	background-color: #F5F5F5;
}

.menu-kategoria div.no-active a p {
	background-color: #F5F5F5;
	border: 1px solid #D4D4D4;
	color: #9ea4a8;
	cursor: not-allowed;
}

.form-navi a p {
	display: inline-block;
	padding: 10px 25px;
	background-color: #2196F3;
	border-radius: 4px;
	color: #ffffff;
}

.menu-kategoria div.active a p {
	background-color: #4CAF50;
	border: 1px solid #FFFFFF;
	color: #ffffff;
}

div.podpis img {
	display: block;
	float: right;
}

div.podpis:after {
	content: ' ';
	display: block;
	clear: both;
}

.editable {
	min-height: 15px;
}

#form-tresc-dokumentu div.manager-comments div.panel-body {
	padding: 10px 10px 20px 10px;

}

div.manager-comments table {
	width: 100%;
	padding:0;
	margin:0;
	table-layout: fixed;
}

div.manager-comments table tr.exam-data-heading td div {
	background-color: #37474F;
	color: #ffffff;
	text-align: center;
	padding: 5px 0;
}

#form-tresc-dokumentu table tr div.manager-comments table tbody tr td {
	padding: 0;
	width: auto;
}



#form-tresc-dokumentu div.kolumna-informacyjna table tbody tr td:nth-child(2) {
    color: #434141;
}

#form-tresc-dokumentu div.kolumna-informacyjna table tbody tr td {
    padding: 0;
    width: auto;
    text-align: center;
}

tr.exam-data-body td {
	background-color: rgba(55, 71, 79, 0.08);
}


table.ramki {
	width: 100%;
	table-layout: fixed;
	border: none;
	padding:0;
	margin:0;
	border-collapse: collapse;
}

#form-tresc-dokumentu table.ramki tr td {
	border: none;
}

table.ramki tr td div {
	border: 2px solid #929292;
    padding: 10px 5px;
    margin: 5px;
}

table.ramki tr td div div {
	border: none;
    padding: 0;
    margin: 0;
}

table.ramki tr td div span {
	display: block;
    text-align: center;
}

table.ramki tr td div p {
	font-size: 13px;
    text-align: center;
    font-weight: bold;
    padding-top: 3px;
}

#form-customer img {
	max-height: 105px;
}

#form-tresc-dokumentu div.form-customer-panel div.panel-body {
	padding:0;
}

#form-tresc-dokumentu div.form-customer-panel div.panel-body table tr td {
	border:none;
	text-align: center;
}

#form-tresc-dokumentu div.form-customer-panel div.panel-body table tr td span {
	display: block;
	text-align: center;
	padding-top:10px;
}

#form-tresc-dokumentu div.form-customer-panel div.panel-body table tr td p {
	font-size: 19px;
	text-align: center;
	font-weight: bold;
	padding-top: 3px;
}



#galeria-zdjec div.kontyner_zdjecia img {
	width: 310px;
	height: 310px;
	border: 1px solid #999999;
	border-radius: 4px;
	opacity: 0.8;
}

#galeria-zdjec div.kontyner_zdjecia a:hover img {
	opacity: 1;
}

#galeria-zdjec div.kontyner_zdjecia {
	float: left;
	position:relative;
	width: 310px;
	height: 310px;
	margin:10px;
}

#galeria-zdjec:after {
	content: ' ';
	display: block;
	clear: both;
}

.pobierz_pdf {
    display: block;
    position: absolute;
    top: 240px;
    left: 240px;
    width: 65px;
    height: 65px;
    background-color: rgba(255,255,255,.6);
    color: #727272;
    text-decoration: none;
    border-radius: 50%;
    background-image: url(../images/pdf-icon.png);
    background-repeat: no-repeat;
    background-size: 45px 45px;
    background-position: center center;
    z-index: 99;
}

.btn {
	border-radius: 3px;
	background-color: #fcfcfc;
    box-shadow: 0 0 0 100px rgba(0,0,0,.01) inset;
    color: #333;
    text-decoration: none;
    padding: 6px 11px;
	display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 13px;
}

.inbox-tabela a.open-multiform {
	padding: 9px 11px;

}

.btn-cancel {
	border-radius: 3px;
	background-color: #fcfcfc;
    box-shadow: 0 0 0 100px rgba(0,0,0,.01) inset;
    color: #333;
    text-decoration: none;
    padding: 6px 11px;
	display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 13px;
}

.btn-delete {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336;
}

.btn-delete:hover {
	color:#4a4a4a;
}

.btn-save {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50;
}

.btn-save:hover {
	color:#4a4a4a;
}

a.mark-notification-as-read, a.delete-notification  {
	border: 1px solid #DDDDDD;
	border-radius: 3px;
	width: 25px;
	height: 28px;
	line-height: 28px;
	text-align: center;
	display: block;
	margin-top:5px;
	font-size: 14px;
}

div.notifications-list div.unread a.mark-notification-as-read {
	margin-right: 3px;
	background-color: #00BCD4;
	border-color: #00BCD4;
	color: #ffffff;
}

a.mark-notification-as-read {
	margin-right: 3px;
	background-color: #37474F;
	border-color: rgba(255, 255, 255, 0.48);
	color: #ffffff;
}

a.mark-notification-as-read:hover {
	background-color: #37474F;
	border-color:rgba(255, 255, 255, 0.48);
}

div.notifications-list div.unread a.mark-notification-as-read:hover {
	background-color: #059caf;
	border-color:#059caf;
}


a.delete-notification {
	background-color: #F44336;
	border-color: #F44336;
	color: #37474F;
}

a.delete-notification:hover {
	background-color: #d0281c;
	border-color: #d0281c;
}

div.notification-controls li {
	vertical-align: middle;
}



td.informacje-top h2 {
	font-size: 24px;
    color: #37474F;
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
    line-height: 60px;
}

.seba-form-margin {
	padding:10px 20px;
}

.seba-form-margin .panel {
	border:none;
	margin-bottom:0;
}

table.ramki-inside {
	width: 100%;
	border-collapse: collapse;
}

table.ramki-inside tr td {
	border:1px dashed #BCBBBB;
	padding: 10px 5px;
	border-collapse: collapse;
}

table.ramki-inside tr td p {
	font-weight: 600;
	color: #2196F3;
	font-size: 16px;
}

table.seba-checkboxy {
	margin:0;
	padding: 0;
	vertical-align: middle;
	border: none;
	width: 100%;
}

table.seba-checkboxy td {
	vertical-align: middle;
	border: none;
	padding: 0;
}

table.ramki-inside table.seba-checkboxy tr td {
	vertical-align: middle;
	border: none;
	padding: 0;
}

table.seba-checkboxy td div.s_check_box {
}

table.seba-checkboxy td div.s_check_box i {
	padding: 2px 3px;
	font-size: 16px;
    text-align: center;
    margin: 0;
    color: #5CB85C;
}

table.seba-checkboxy td .s_check_txt, table.seba-checkboxy td div.s_check_box {
	margin-bottom: 3px;
}

td.seba-col-checkbox {
	width: 28px;
}

.seba-przelam {
	display: block;
	font-size: 12px;
	color: #626262;
}

img.presafety_podpis {
	max-width: 250px;
	display: block;
}

td.kto-placi2 div {
	padding-top:15px;
}

td.kto-placi2 h2 {
	line-height: 28px;
}

td.kto-placi2 p {
	text-align: center;
	font-size: 20px;
	text-transform: uppercase;
}

.user-niebieski {
	font-weight: 600;
    color: #2196F3;
    font-size: 16px;
}

.btn-podpis {
	display: block;
	padding: 15px 35px;
	text-align: center;
	background-color: #1E87DB;
	border-radius: 4px;
	outline: none;
	border: none;
	color: #ffffff;
	margin: 0 auto;
	cursor: pointer;
}

.btn-podpis:hover {
	background-color: #2196F3;
}

td.select-checkbox, th.select-checkbox {
	width: 50px !important;
}

.zawartosc-podstrony div.panel {
	margin:20px;
}

.zawartosc-podstrony div.panel .panel{
	margin:0;
}

.kolumna-informacyjna div.editable {
	min-height: 19px;
}

.kolumna-informacyjna div.editable p {
	min-height: 19px;
}

.kolumna-informacyjna .editable textarea {
	width: 100%;
	min-height:35px;
}


div#form-mapa iframe {
	width: 100%;
}


.dataTable tr.multiform-row td {
	background-color: #f2eec4;
}

.inbox-tabela tbody tr td table tr td.multiform-row {
	width: 100% !important;
	padding: 0;
	margin:0;
}




table.dataTable thead th, table.dataTable thead td {
    padding: 10px 3px;
}

td.select-checkbox, th.select-checkbox  {
	width: 50px !important;
	padding: 8px 10px !important;
}

tr.multiform-row td.multiform-row {
	padding: 0;
	margin:0;
	width: 100%;
}

table.table thead tr th.sorting {
	width: auto !important;
}

div.dataTables_info {
	padding: 25px 15px;
	padding-top:25px;
}

#forms-table_info {
	padding-top:25px;
}

#forms-table_paginate {
	padding: 25px 15px;
}

.editable p:empty:before,
.editable div:empty:before
{
    content: '\f040';
    font-family: FontAwesome;
    color: rgb(33, 150, 243);
    cursor: pointer;
}

.editable p:empty:after,
.editable div:empty:after
{
    content: 'click and input text';
    color: rgb(33, 150, 243);
    cursor: pointer;
    display: inline-block;
    padding-left: 10px;
}

.exam-data-body p {
	text-align: center;
	line-height: 35px;
}

.exam-data-body td input {
	display: block;
	margin: 0 auto;
	position: relative;
	top: 7px;
	text-align: center;
}

.kolumna-informacyjna table.ramki td input {
	display: block;
	margin: 0 auto;
	text-align: center;
}

div.editable p, p.editable-devicetype, p.editable-place {
	cursor: pointer;
}

div.manager-comments div.panel-body table tr:last-child div.editable {
	padding: 15px;
}

div.work-arrival input {
	font-weight: bold;
}

div.editable input {
	display: block;
	margin:0 auto;
	text-align: center;
}

div.editable textarea {
	display: block;
	width: 100%;
}

table.ramki-inside div.editable input {
	margin:0;
	font-weight: 600;
	color: #2196F3;
	font-size: 16px;
	text-align: left;
}

div.popup div.popup-element {
	border-radius: 6px;
}

div.popup h3 {
	font-weight: bold;
	font-size: 18px;
	padding-bottom: 5px;
}

div.popup div.popup-element p {
	margin-bottom: 10px;
	line-height: 1.4;
}

div.popup div.popup-element {
	position: relative;
}

div.popup div.popup-element .btn-save {
	margin: 15px 0 15px -60px;
	padding: 10px 35px;
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
}

div.okno-margin {
	padding-bottom:110px;
}

/* K.FORMULARZ */

#clear-daterangepicker {
	display: inline-block;
    position: absolute;
    right: 11px;
    bottom: -19px;
}

div.kategoria-tytul span.badge-green {
	display: inline-block;
	padding: 3px 5px;
	border-radius: 3px;
	background-color: #4CAF50;
	color: #ffffff;
}

div.kategoria-tytul span.badge-blue {
	display: inline-block;
	padding: 3px 5px;
	border-radius: 3px;
	background-color: #00BCD4;
	color: #ffffff;
}

div.kategoria-tytul span.badge-brown {
	display: inline-block;
	padding: 3px 5px;
	border-radius: 3px;
	background-color: #795548;
	color: #ffffff;
}

div.kategoria-tytul span.badge-red {
	display: inline-block;
	padding: 3px 5px;
	border-radius: 3px;
	background-color: #C62828;
	color: #ffffff;
}

div.kategoria-tytul span.badge-purple {
	display: inline-block;
	padding: 3px 5px;
	border-radius: 3px;
	background-color: #C62828;
	color: #ffffff;
}

div.nawigacja div.komunikaty {
	position: absolute;
	right: 0;
	top:50px;
}


div.nawigacja div.komunikaty ul, div.nawigacja div.komunikaty ul li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

div.nawigacja div.komunikaty ul li {
	padding: 15px;
	background-color: #1E87DB;
	border-radius: 6px;
	margin: 5px;
}

div.nawigacja div.komunikaty ul li i {
	display: inline-block;
	margin-right:10px;
}

div.menu-glowne li a span.counter {
	background-color: #f31616;
	color: #ffffff;
	font-weight: bold;
	border-radius: 4px;
	padding: 3px 5px;
}

div.send_email_popup h3.tytul {
	position: relative;
    padding: 15px 20px 15px 20px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #009688;
    border-color: #009688;
    color: #fff;
}

body div.send_email_popup h3.tytul {
	border-left-width: 44px;
	border-left-color: rgba(0,0,0,.15)!important;
}

#financial_comment form, .popup_form form {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    width: 60%;
    padding: 35px;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
    animation: pokazKomunikat 0.3s;
}
