.dataTable td{
    text-align: center;
}

.dataTable td > table {
    width: 100%;
}

.row{
    padding: 10px;
}

.col-md-8,
.col-md-3{
    display: inline-block;
    vertical-align: top;
}

.col-md-8{
    width: 66%;
}

.col-md-3{
    width:25%;
}

.reports_content_container{
    padding-top: 70px;
    padding-left: 25px;
    padding-right: 25px;
}

.select_years{
    position: absolute;
    left: 0;
    background: #fff;
    border: 1px solid #898989;
    padding: 20px;
    display: none;
    z-index: 1000;
    min-width: 159px;
    border-radius: 6px;
}

.select_years > div{
    display: inline-block;
    float: left;
    margin-left: 10px;
}

canvas{
    z-index: 1;
}

.date_selection{
    position: relative;
}



.panel {
	border: 1px solid #BCBBBB;
	border-radius: 9px;
	margin: 0 0 15px 0;
}

.panel-title {
	background-color: #F7F9FA;
	border-bottom: 1px solid #BCBBBB;
	padding: 5px 0 5px 15px;
	min-height:55px;
}

.panel-title .przycisk_prawa {
	float: right;
	padding-right: 15px;
	padding-top: 7px;
}

.panel-title h3 {
	margin: 0;
	padding: 0;
	font-size: 19px;
    font-weight: 700;
    color: #4d4c4c;
    float: left;
    line-height: 22px;
}

.panel-title h3 span {
    font-weight: 100;
    font-size: 16px;
}

.panel p {
	padding: 0;
}

.panel-title:after {
	content: ' ';
	display: block;
	clear: both;
}

.btn-wybor-daty {
	color: #363636;
    background-color: #f7f9fa;
    padding: 0;
    border: 1px solid #2d61e6;
}

.btn-wybor-daty:hover {
	color: #363636;
    background-color: #ffffff;
    padding: 0;
    border: 1px solid #2d61e6;
}

.nazwa_miesiaca {
	color: #4caf50;
}

.btn{

}

a.btn{
    text-decoration: none;
}

.btn-option{
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    margin-right: 2px;
}

.btn-option:hover{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.dataTable .even{
    background-color: #f7f7f7;
}

.dataTable tbody tr:hover{
    background-color: #f0f0f0;
}






.main_form{ width: 1030px; float:left; }

.form_right_column{
    width: 310px;
    float: right;
}

.form_photos .thumb{
    width: 150px;
    height: 100px;
}

.form_photos .photo_container{
    position: relative;
    display: inline-block;
}

.form_photos .view_photo,
.form_photos .get_pdf
{
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 20px;
    left: 70px;
    background-color: rgba(255,255,255,.6);
    color: #727272;
    font-size: 28px;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
}

.form_photos .get_pdf{
    left: 20px;
}


.popup{
    box-sizing: border-box;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 10%;
    z-index: 100;
    flex-direction: column;
    display: none;
}

.popup-row{
    flex: 1;
    display: flex;
    padding: 5px;
    width: 100%;
}

.popup-element{
    flex: 1;
    background-color: #fff;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.popup-element input[type='text'],
.popup-element select
{
    padding: 5px;
    width: 100%;
}

.popup-element button{
    padding: 5px 15px;
    font-size: 15px;
}

.popup .popup-close{
    float: right;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    color: #333;
    border:1px solid #333;
    border-radius: 50%;
}

.popup-element .select2-container{
    width: 100% !important;
}

/* Skradzione ze starego maximusa */

#financial_comment,
.popup_form{
    display: none;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index:1000;
}

#financial_comment form,
.popup_form form{
    display: block;
    margin-top:150px;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding:10px;
    background-color: #fff;
}

#financial_comment textarea,
.popup_form textarea{
    width:100%;
    height: 300px;
}

#financial_comment,
.popup_form{
    background-color: rgba(0, 0, 0, 0.4);

}

#financial_comment form ,
.popup_form form{
    padding: 25px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    width: 478px;
    padding: 17px;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
    animation: pokazKomunikat 0.3s;
}

#financial_comment form p ,
.popup_form form p{
    font-size: 16px;
    padding: 15px 0;
}

#financial_comment form textarea,
.popup_form form textarea {
    border-color: #CDCDCD;
}

.btn-seba {
    padding: 12px 20px;
    color: #ffffff;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: none;
    border: none;
    outline: none;
}

.select2{
    width:100%;
    font-size:14px;
}

.saved{
    border-color: #194906;
    background-color: #e7ffdd;
}


/* RAPORTY - skanibalizowane z projektu */
/* **********************   */

.box-raport {
    border-radius: 3px;
    box-shadow: 0 1px 10px rgba(0,0,0,.05);
    margin-bottom: 10px;
    position: relative;
    padding: 15px 15px 0 15px;
    height: 150px;
}

.box-raport-zielony {
    background-color: #26A69A;
    color: #fff;
}

.box-raport-niebieski {
    background-color: #03A9F4;
    color: #fff;
}

.box-raport-rozowy {
    background-color: #EC407A;
    color: #fff;
}

.box-raport-fiolet {
    background-color: #5C6BC0;
    color: #fff;
}

.box-raport-szary {
    background-color: #37474F;
    color: #fff;
}


.box-raport .zmiana {
    position: absolute;
    right: 0;
    top: 0;
    margin: 15px 15px 0 0;
    background-color: rgba(0, 0, 0, 0.49);
    border-radius: 100px;
    color: #ffffff;
    font-size: 12px;
    padding: 6px 10px;
}

.box-raport h3 {
    margin: 0 0 3px 0;
    font-size: 28px;
    font-weight: bold;
}

.box-raport h4 {
    margin: 5px 0;
    font-size: 12px;
    font-weight: normal;
    color: #FFFFFF;
}

.box-raport h5 {
    margin: 5px 0;
    font-size: 12px;
    font-weight: normal;
    color: #FFFFFF;
    opacity: 0.8;
}

.panel-heading {
    position: relative;
    vertical-align: middle;
    display: table;
    width: 100%;
}

.panel-heading h6 {
    display: table-cell;
    font-size: 14px;
    padding: 0;
    margin: 0;
    vertical-align: middle;
}

.panel-heading .panel-kontrolki {
    height: 100%;
    padding-right:5px;
    display: table-cell;
    float: right;
}

.panel-heading:after {
    content: ' ';
    display: block;
    clear: both;
}

.panel-kontrolki .input-group {
    height: 100%;
    vertical-align: middle;
}

.panel-kontrolki .input-group input {
    vertical-align: middle;
}


.panel-kontrolki .input-group input {
    display: table-cell;
    position: relative;
    z-index: 2;
    box-shadow: none;
    height: 30px;
    padding: 0 0 0 35px;
    background-color: #ffffff;
    border: none;
    font-size: 13px;
    color: #37474F;
    border-radius: 3px;
    cursor: pointer;
}

.panel-kontrolki .input-group:before {
    content: "\f073";
    position: absolute;
    left: 0;
    top: 50%;
    font-family: FontAwesome;
    color: #37474F;
    z-index: 99;
    margin-top: -11px;
    margin-left: 10px;
}

.zawartosc-podstrony .panel-body-nopadding {
    padding: 0px;
}

.standardowa-tabela {
    width: 100%;
}

.standardowa-tabela thead th, .standardowa-tabela tbody td {
    vertical-align: middle;
}

.standardowa-tabela thead th {
    border-bottom: 1px solid #bbb;
    padding: 12px 20px;
    font-weight: 600;
    background-color: #F8F8F8;
}

.standardowa-tabela tbody td {
    padding: 12px 20px;
    border-bottom: 1px solid #DDDDDD;
}

.standardowa-tabela tbody td.rosnie {
    color: #48A047;
}

.standardowa-tabela tbody tr:hover td {
    background-color: rgba(0, 0, 0, 0.03);
}

.standardowa-tabela tbody td.maleje {
    color: #F4438D;
}

.standardowa-tabela tbody td.android {
    overflow-wrap: no-wrap;
}

.standardowa-tabela tbody td.android span {
    color: #676767;
    font-size: 9px;
    display: block;
    white-space: nowrap;
}

.standardowa-tabela td.user-img img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    vertical-align: middle;
}

img.user-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
    margin: 0 5px 0 0;
}

ul.sub-nawigacja li {
    text-decoration: none;
    padding: 15px 10px;
    border-radius: 6px;
}

ul.sub-nawigacja li:hover {
    background-color: #ececec;
}

ul.sub-nawigacja li a {
    font-size: 12px;
}


ul.sub-nawigacja li.active {
    background-color: #26A69A;
    color: #F5F5F5;
    text-decoration: none;
    padding: 15px 10px;
    border-radius: 6px;
}

ul.sub-nawigacja li.active a {
    color: #ffffff;
    text-decoration: none;
}

/* K.RAPORTY */

.chart-tooltip{
    position: absolute;
    left: 150px;
    top: 150px;
    z-index: 1000;
    background-color: rgba(0,0,0,0.7);
    padding: 5px;
    color: #fff;
}

.chart-tooltip h3{
    font-weight: 700;
}

.chart-tooltip:empty{
    display: none;
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#notifications-nav-button p {
    display: none;
}

#notifications-nav-button.new p{
    display: block;
}

.notification .mark-notification-as-read{
    display: none;
}

.notification .unread .mark-notification-as-read{
    display: inline-block;
}

.clear-input{
    float:right;
}

.editable-checkbox{
    width: 100%;
    height: 10px;
    cursor: pointer;
}

.notes .note{
    display: inline-block;
}

.notes .note img{
    max-height: 300px;
    max-width: 200px;
}

.fc-body tbody tr{
    background-color: rgba(255,0,0,0.1);
}

.fc-body tbody tr[data-time="08:00:00"],
.fc-body tbody tr[data-time="08:30:00"],
.fc-body tbody tr[data-time="09:00:00"],
.fc-body tbody tr[data-time="09:30:00"],
.fc-body tbody tr[data-time="10:00:00"],
.fc-body tbody tr[data-time="10:30:00"],
.fc-body tbody tr[data-time="11:00:00"],
.fc-body tbody tr[data-time="11:30:00"],
.fc-body tbody tr[data-time="12:00:00"],
.fc-body tbody tr[data-time="12:30:00"],
.fc-body tbody tr[data-time="13:00:00"],
.fc-body tbody tr[data-time="13:30:00"],
.fc-body tbody tr[data-time="14:00:00"],
.fc-body tbody tr[data-time="14:30:00"],
.fc-body tbody tr[data-time="15:00:00"],
.fc-body tbody tr[data-time="15:30:00"],
.fc-body tbody tr[data-time="16:00:00"],
.fc-body tbody tr[data-time="16:30:00"]
{
    background-color: rgba(0,255,0,0.1);
}


.sortable{
    min-height: 30px;
    background-color: #fff;
}

.sortable li {
    height: 20px;
    border: 1px solid #333;
    padding: 5px 10px;
    margin:3px;
    border-radius: 5px;
}

.draggable.modal{
    max-width: 700px;
}

.close-modal-button{
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 25px;
}

.editable-presafety-checkbox,
.editable-checkbox-specific-method
{
    cursor: pointer;
}

.kategoria-zawartosc h3{
    color: #333;
    padding-top: 10px;
    border-bottom: 1px solid #333;
}

/* rzeczy od uploadu schematów */

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
    display: none;
}

.box.has-advanced-upload {
  background-color: white;
  outline: 2px dashed black;
  outline-offset: -10px;
}
.box.has-advanced-upload .box__dragndrop {
  display: inline;
}
.box
{
    font-size: 1.25rem; /* 20 */
    background-color: #c8dadf;
    position: relative;
    padding: 100px 20px;
}
.box.has-advanced-upload
{
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;

    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
}
.box.is-dragover
{
    outline-offset: -20px;
    outline-color: #c8dadf;
    background-color: #fff;
}
.box__dragndrop,
.box__icon
{
    display: none;
}
.box.has-advanced-upload .box__dragndrop
{
    display: inline;
}
.box.has-advanced-upload .box__icon
{
    width: 100%;
    height: 80px;
    fill: #92b0b3;
    display: block;
    margin-bottom: 40px;
}

.box.is-uploading .box__input,
.box.is-success .box__input,
.box.is-error .box__input
{
    visibility: hidden;
}

.box__uploading,
.box__success,
.box__error
{
    display: none;
}
.box.is-uploading .box__uploading,
.box.is-success .box__success,
.box.is-error .box__error
{
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    -webkit-transform: translateY( -50% );
    transform: translateY( -50% );
}
.box__uploading
{
    font-style: italic;
}
.box__success
{
    -webkit-animation: appear-from-inside .25s ease-in-out;
    animation: appear-from-inside .25s ease-in-out;
}
    @-webkit-keyframes appear-from-inside
    {
        from    { -webkit-transform: translateY( -50% ) scale( 0 ); }
        75%     { -webkit-transform: translateY( -50% ) scale( 1.1 ); }
        to      { -webkit-transform: translateY( -50% ) scale( 1 ); }
    }
    @keyframes appear-from-inside
    {
        from    { transform: translateY( -50% ) scale( 0 ); }
        75%     { transform: translateY( -50% ) scale( 1.1 ); }
        to      { transform: translateY( -50% ) scale( 1 ); }
    }

.box__restart
{
    font-weight: 700;
}
.box__restart:focus,
.box__restart:hover
{
    color: #39bfd3;
}

.js .box__file
{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.js .box__file + label
{
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
}
.js .box__file + label:hover strong,
.box__file:focus + label strong,
.box__file.has-focus + label strong
{
    color: #39bfd3;
}
.js .box__file:focus + label,
.js .box__file.has-focus + label
{
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}
    .js .box__file + label *
    {
        /* pointer-events: none; */ /* in case of FastClick lib use */
    }

.no-js .box__file + label
{
    display: none;
}

.no-js .box__button
{
    display: block;
}
.box__button
{
    font-weight: 700;
    color: #e5edf1;
    background-color: #39bfd3;
    display: none;
    padding: 8px 16px;
    margin: 40px auto 0;
}
.box__button:hover,
.box__button:focus
{
    background-color: #0f3c4b;
}

.user-round-icon{
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 10px;
    line-height: normal;
}

.sub-nawigacja li {
    line-height: 20px;
}

.categories{
    padding-left: 10px;
}

.category{
    color: #333;
}

.category_selector{
    display: flex;
}

.category_selector .category_list{
    flex: 1;
}

.category_list .selected{
    background-color: #e7ffdd;
}

.editable-signature{
    cursor: pointer;
    -webkit-transition: opacity .15s ease-in-out;
    -moz-transition: opacity .15s ease-in-out;
    -ms-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
    min-width: 10px;
    min-height: 10px;
}

.editable-signature:hover{
    opacity: 0.5;
    filter: alpha(opacity=50);

}

.popup-content{
    display: flex;
    background-color: #fff;
}

.popup .close-popup{
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    border:1px solid #333;
}

.flex-column{
    flex:1;
    padding: 10px;
}

.thex-signature img{
    max-width: 250px;
}

.serial_numbers{
    display: flex;
    flex-wrap: wrap;
    overflow-x: none;
}

.serial_numbers input{
    flex:1;
    min-width: 200px;
}

.photos .photo{
    display: inline-block;
    width: 200px;
    height: 200px;
    margin-right: 10px;
    position: relative;
}

.photo .delete-button{
    position: absolute;
    right:0;
    top: 0;
    width: 30px;
    height: 30px;
}

.surveys h2{
    font-size: 20px;
    margin-top: 10px;
    clear: both;
}

.surveys label{
    margin-bottom: 10px;
    font-weight: 700;
    padding-left: 15px;
    font-size: 14px;
    margin-top: 10px;
}

.surveys .form-group{
    clear: both;
}